import Loader from "@mobi/ds/components/Loader"

import useProcessData from "./hooks"

const ProcessData = () => {
  useProcessData()

  return <Loader isVisible />
}

export default ProcessData
